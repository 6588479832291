var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0) t[p[i]] = s[p[i]];
  }
  return t;
};

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import { withStyles, createStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import classnames from 'classnames';
import { hideNotification, getNotification, translate, undo, complete } from 'ra-core';

var styles = function styles(theme) {
  return createStyles({
    confirm: {
      backgroundColor: theme.palette.background.default
    },
    warning: {
      backgroundColor: theme.palette.error.light
    },
    undo: {
      color: theme.palette.primary.light
    }
  });
};

var Notification =
/** @class */
function (_super) {
  __extends(Notification, _super);

  function Notification() {
    var _this = _super !== null && _super.apply(this, arguments) || this;

    _this.state = {
      open: false
    };

    _this.componentWillMount = function () {
      _this.setOpenState(_this.props);
    };

    _this.componentWillReceiveProps = function (nextProps) {
      _this.setOpenState(nextProps);
    };

    _this.setOpenState = function (_a) {
      var notification = _a.notification;

      _this.setState({
        open: !!notification
      });
    };

    _this.handleRequestClose = function () {
      _this.setState({
        open: false
      });
    };

    _this.handleExited = function () {
      var _a = _this.props,
          notification = _a.notification,
          hideNotification = _a.hideNotification,
          complete = _a.complete;

      if (notification && notification.undoable) {
        complete();
      }

      hideNotification();
    };

    return _this;
  }

  Notification.prototype.render = function () {
    var _a = this.props,
        undo = _a.undo,
        complete = _a.complete,
        classes = _a.classes,
        className = _a.className,
        type = _a.type,
        translate = _a.translate,
        notification = _a.notification,
        autoHideDuration = _a.autoHideDuration,
        hideNotification = _a.hideNotification,
        rest = __rest(_a, ["undo", "complete", "classes", "className", "type", "translate", "notification", "autoHideDuration", "hideNotification"]);

    var warning = classes.warning,
        confirm = classes.confirm,
        undoClass = classes.undo,
        // Rename classes.undo to undoClass in this scope to avoid name conflicts
    snackbarClasses = __rest(classes, ["warning", "confirm", "undo"]);

    return React.createElement(Snackbar, __assign({
      open: this.state.open,
      message: notification && notification.message && translate(notification.message, notification.messageArgs),
      autoHideDuration: notification && notification.autoHideDuration || autoHideDuration,
      disableWindowBlurListener: notification && notification.undoable,
      onExited: this.handleExited,
      onClose: this.handleRequestClose,
      ContentProps: {
        className: classnames(classes[notification && notification.type || type], className)
      },
      action: notification && notification.undoable ? React.createElement(Button, {
        color: "primary",
        className: undoClass,
        size: "small",
        onClick: undo
      }, translate('ra.action.undo')) : null,
      classes: snackbarClasses
    }, rest));
  };

  return Notification;
}(React.Component);

Notification.propTypes = {
  complete: PropTypes.func,
  classes: PropTypes.object,
  className: PropTypes.string,
  notification: PropTypes.shape({
    message: PropTypes.string,
    type: PropTypes.string,
    autoHideDuration: PropTypes.number,
    messageArgs: PropTypes.object
  }),
  type: PropTypes.string,
  hideNotification: PropTypes.func.isRequired,
  autoHideDuration: PropTypes.number,
  translate: PropTypes.func.isRequired,
  undo: PropTypes.func
};
Notification.defaultProps = {
  type: 'info',
  autoHideDuration: 4000
};

var mapStateToProps = function mapStateToProps(state) {
  return {
    notification: getNotification(state)
  };
};

export default compose(translate, withStyles(styles), connect(mapStateToProps, {
  complete: complete,
  hideNotification: hideNotification,
  undo: undo
}))(Notification);