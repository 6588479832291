var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0) t[p[i]] = s[p[i]];
  }
  return t;
};

import React, { Children, Fragment, isValidElement } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import MuiToolbar from '@material-ui/core/Toolbar';
import withWidth from '@material-ui/core/withWidth';
import { withStyles, createStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { SaveButton, DeleteButton } from '../button';

var styles = function styles(theme) {
  var _a;

  return createStyles({
    toolbar: {
      backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[100] : theme.palette.grey[900]
    },
    desktopToolbar: {
      marginTop: theme.spacing.unit * 2
    },
    mobileToolbar: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      padding: '16px',
      width: '100%',
      boxSizing: 'border-box',
      flexShrink: 0,
      zIndex: 2
    },
    defaultToolbar: {
      flex: 1,
      display: 'flex',
      justifyContent: 'space-between'
    },
    spacer: (_a = {}, _a[theme.breakpoints.down('xs')] = {
      height: '5em'
    }, _a)
  });
};

var valueOrDefault = function valueOrDefault(value, defaultValue) {
  return typeof value === 'undefined' ? defaultValue : value;
};

var Toolbar = function Toolbar(_a) {
  var basePath = _a.basePath,
      children = _a.children,
      classes = _a.classes,
      className = _a.className,
      handleSubmit = _a.handleSubmit,
      handleSubmitWithRedirect = _a.handleSubmitWithRedirect,
      invalid = _a.invalid,
      pristine = _a.pristine,
      record = _a.record,
      redirect = _a.redirect,
      resource = _a.resource,
      saving = _a.saving,
      submitOnEnter = _a.submitOnEnter,
      undoable = _a.undoable,
      width = _a.width,
      rest = __rest(_a, ["basePath", "children", "classes", "className", "handleSubmit", "handleSubmitWithRedirect", "invalid", "pristine", "record", "redirect", "resource", "saving", "submitOnEnter", "undoable", "width"]);

  var _b;

  return React.createElement(Fragment, null, React.createElement(MuiToolbar, __assign({
    className: classnames(classes.toolbar, (_b = {}, _b[classes.mobileToolbar] = width === 'xs', _b[classes.desktopToolbar] = width !== 'xs', _b), className),
    role: "toolbar"
  }, rest), Children.count(children) === 0 ? React.createElement("div", {
    className: classes.defaultToolbar
  }, React.createElement(SaveButton, {
    handleSubmitWithRedirect: handleSubmitWithRedirect,
    invalid: invalid,
    redirect: redirect,
    saving: saving,
    submitOnEnter: submitOnEnter
  }), record && typeof record.id !== 'undefined' && React.createElement(DeleteButton, {
    basePath: basePath,
    record: record,
    resource: resource,
    undoable: undoable
  })) : Children.map(children, function (button) {
    return button && isValidElement(button) ? React.cloneElement(button, {
      basePath: basePath,
      handleSubmit: valueOrDefault(button.props.handleSubmit, handleSubmit),
      handleSubmitWithRedirect: valueOrDefault(button.props.handleSubmitWithRedirect, handleSubmitWithRedirect),
      invalid: invalid,
      pristine: pristine,
      record: record,
      resource: resource,
      saving: saving,
      submitOnEnter: valueOrDefault(button.props.submitOnEnter, submitOnEnter),
      undoable: valueOrDefault(button.props.undoable, undoable)
    }) : null;
  })), React.createElement("div", {
    className: classes.spacer
  }));
};

Toolbar.propTypes = {
  basePath: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleSubmitWithRedirect: PropTypes.func,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  record: PropTypes.object,
  redirect: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.func]),
  resource: PropTypes.string,
  saving: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  submitOnEnter: PropTypes.bool,
  undoable: PropTypes.bool,
  width: PropTypes.string
};
Toolbar.defaultProps = {
  submitOnEnter: true
};
var enhance = compose(withWidth(), withStyles(styles));
export default enhance(Toolbar);