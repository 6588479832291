var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0) t[p[i]] = s[p[i]];
  }
  return t;
};

import React, { Component, isValidElement, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { sanitizeListRestProps } from 'ra-core';
import { withStyles, createStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import classnames from 'classnames';
import DatagridHeaderCell from './DatagridHeaderCell';
import DatagridBody from './DatagridBody';
import DatagridLoading from './DatagridLoading';

var styles = function styles(theme) {
  return createStyles({
    table: {
      tableLayout: 'auto'
    },
    thead: {},
    tbody: {
      height: 'inherit'
    },
    headerRow: {},
    headerCell: {
      padding: '0 12px',
      '&:last-child': {
        padding: '0 12px'
      }
    },
    checkbox: {},
    row: {},
    clickableRow: {
      cursor: 'pointer'
    },
    rowEven: {},
    rowOdd: {},
    rowCell: {
      padding: '0 12px',
      '&:last-child': {
        padding: '0 12px'
      }
    },
    expandHeader: {
      padding: 0,
      width: 48
    },
    expandIconCell: {
      width: 48
    },
    expandIcon: {
      transform: 'rotate(-90deg)',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest
      })
    },
    expanded: {
      transform: 'rotate(0deg)'
    }
  });
};
/**
 * The Datagrid component renders a list of records as a table.
 * It is usually used as a child of the <List> and <ReferenceManyField> components.
 *
 * Props:
 *  - rowStyle
 *
 * @example Display all posts as a datagrid
 * const postRowStyle = (record, index) => ({
 *     backgroundColor: record.nb_views >= 500 ? '#efe' : 'white',
 * });
 * export const PostList = (props) => (
 *     <List {...props}>
 *         <Datagrid rowStyle={postRowStyle}>
 *             <TextField source="id" />
 *             <TextField source="title" />
 *             <TextField source="body" />
 *             <EditButton />
 *         </Datagrid>
 *     </List>
 * );
 *
 * @example Display all the comments of the current post as a datagrid
 * <ReferenceManyField reference="comments" target="post_id">
 *     <Datagrid>
 *         <TextField source="id" />
 *         <TextField source="body" />
 *         <DateField source="created_at" />
 *         <EditButton />
 *     </Datagrid>
 * </ReferenceManyField>
 */


var Datagrid =
/** @class */
function (_super) {
  __extends(Datagrid, _super);

  function Datagrid() {
    var _this = _super !== null && _super.apply(this, arguments) || this;

    _this.updateSort = function (event) {
      event.stopPropagation();

      _this.props.setSort(event.currentTarget.dataset.sort);
    };

    _this.handleSelectAll = function (event) {
      var _a = _this.props,
          onSelect = _a.onSelect,
          ids = _a.ids,
          selectedIds = _a.selectedIds;

      if (event.target.checked) {
        onSelect(ids.reduce(function (idList, id) {
          return idList.includes(id) ? idList : idList.concat(id);
        }, selectedIds));
      } else {
        onSelect([]);
      }
    };

    return _this;
  }

  Datagrid.prototype.render = function () {
    var _this = this;

    var _a = this.props,
        basePath = _a.basePath,
        body = _a.body,
        children = _a.children,
        classes = _a.classes,
        className = _a.className,
        currentSort = _a.currentSort,
        data = _a.data,
        expand = _a.expand,
        hasBulkActions = _a.hasBulkActions,
        hover = _a.hover,
        ids = _a.ids,
        isLoading = _a.isLoading,
        loadedOnce = _a.loadedOnce,
        onSelect = _a.onSelect,
        onToggleItem = _a.onToggleItem,
        resource = _a.resource,
        rowClick = _a.rowClick,
        rowStyle = _a.rowStyle,
        selectedIds = _a.selectedIds,
        setSort = _a.setSort,
        total = _a.total,
        version = _a.version,
        rest = __rest(_a, ["basePath", "body", "children", "classes", "className", "currentSort", "data", "expand", "hasBulkActions", "hover", "ids", "isLoading", "loadedOnce", "onSelect", "onToggleItem", "resource", "rowClick", "rowStyle", "selectedIds", "setSort", "total", "version"]);
    /**
     * if loadedOnce is false, the list displays for the first time, and the dataProvider hasn't answered yet
     * if loadedOnce is true, the data for the list has at least been returned once by the dataProvider
     * if loadedOnce is undefined, the Datagrid parent doesn't track loading state (e.g. ReferenceArrayField)
     */


    if (loadedOnce === false) {
      return React.createElement(DatagridLoading, {
        classes: classes,
        className: className,
        expand: expand,
        hasBulkActions: hasBulkActions,
        nbChildren: React.Children.count(children)
      });
    }
    /**
     * Once loaded, the data for the list may be empty. Instead of
     * displaying the table header with zero data rows,
     * the datagrid displays nothing in this case.
     */


    if (!isLoading && (ids.length === 0 || total === 0)) {
      return null;
    }
    /**
     * After the initial load, if the data for the list isn't empty,
     * and even if the data is refreshing (e.g. after a filter change),
     * the datagrid displays the current data.
     */


    return React.createElement(Table, __assign({
      className: classnames(classes.table, className)
    }, sanitizeListRestProps(rest)), React.createElement(TableHead, {
      className: classes.thead
    }, React.createElement(TableRow, {
      className: classnames(classes.row, classes.headerRow)
    }, expand && React.createElement(TableCell, {
      className: classes.expandHeader
    }), hasBulkActions && React.createElement(TableCell, {
      padding: "none"
    }, React.createElement(Checkbox, {
      className: "select-all",
      color: "primary",
      checked: selectedIds.length > 0 && ids.length > 0 && !ids.find(function (it) {
        return selectedIds.indexOf(it) === -1;
      }),
      onChange: this.handleSelectAll
    })), Children.map(children, function (field, index) {
      return isValidElement(field) ? React.createElement(DatagridHeaderCell, {
        className: classes.headerCell,
        currentSort: currentSort,
        field: field,
        isSorting: currentSort.field === (field.props.sortBy || field.props.source),
        key: field.props.source || index,
        resource: resource,
        updateSort: _this.updateSort
      }) : null;
    }))), cloneElement(body, {
      basePath: basePath,
      className: classes.tbody,
      classes: classes,
      expand: expand,
      rowClick: rowClick,
      data: data,
      hasBulkActions: hasBulkActions,
      hover: hover,
      ids: ids,
      isLoading: isLoading,
      onToggleItem: onToggleItem,
      resource: resource,
      rowStyle: rowStyle,
      selectedIds: selectedIds,
      version: version
    }, children));
  };

  return Datagrid;
}(Component);

Datagrid.propTypes = {
  basePath: PropTypes.string,
  body: PropTypes.element.isRequired,
  children: PropTypes.node.isRequired,
  classes: PropTypes.object,
  className: PropTypes.string,
  currentSort: PropTypes.shape({
    field: PropTypes.string,
    order: PropTypes.string
  }),
  data: PropTypes.object.isRequired,
  expand: PropTypes.node,
  hasBulkActions: PropTypes.bool.isRequired,
  hover: PropTypes.bool,
  ids: PropTypes.arrayOf(PropTypes.any).isRequired,
  isLoading: PropTypes.bool,
  onSelect: PropTypes.func,
  onToggleItem: PropTypes.func,
  resource: PropTypes.string,
  rowClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  rowStyle: PropTypes.func,
  selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
  setSort: PropTypes.func,
  total: PropTypes.number,
  version: PropTypes.number
};
Datagrid.defaultProps = {
  data: {},
  hasBulkActions: false,
  ids: [],
  selectedIds: [],
  body: React.createElement(DatagridBody, null)
};
export default withStyles(styles)(Datagrid);