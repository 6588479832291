var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0) t[p[i]] = s[p[i]];
  }
  return t;
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles, createStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import { addField, translate, FieldTitle } from 'ra-core';
import defaultSanitizeRestProps from './sanitizeRestProps';

var sanitizeRestProps = function sanitizeRestProps(_a) {
  var setFilter = _a.setFilter,
      setPagination = _a.setPagination,
      setSort = _a.setSort,
      rest = __rest(_a, ["setFilter", "setPagination", "setSort"]);

  return defaultSanitizeRestProps(rest);
};

var styles = function styles(theme) {
  return createStyles({
    root: {},
    label: {
      transform: 'translate(0, 1.5px) scale(0.75)',
      transformOrigin: "top " + (theme.direction === 'ltr' ? 'left' : 'right')
    },
    checkbox: {
      height: 32
    }
  });
};
/**
 * An Input component for a checkbox group, using an array of objects for the options
 *
 * Pass possible options as an array of objects in the 'choices' attribute.
 *
 * The expected input must be an array of identifiers (e.g. [12, 31]) which correspond to
 * the 'optionValue' of 'choices' attribute objects.
 *
 * By default, the options are built from:
 *  - the 'id' property as the option value,
 *  - the 'name' property an the option text
 * @example
 * const choices = [
 *     { id: 12, name: 'Ray Hakt' },
 *     { id: 31, name: 'Ann Gullar' },
 *     { id: 42, name: 'Sean Phonee' },
 * ];
 * <CheckboxGroupInput source="recipients" choices={choices} />
 *
 * You can also customize the properties to use for the option name and value,
 * thanks to the 'optionText' and 'optionValue' attributes.
 * @example
 * const choices = [
 *    { _id: 123, full_name: 'Leo Tolstoi' },
 *    { _id: 456, full_name: 'Jane Austen' },
 * ];
 * <CheckboxGroupInput source="recipients" choices={choices} optionText="full_name" optionValue="_id" />
 *
 * `optionText` also accepts a function, so you can shape the option text at will:
 * @example
 * const choices = [
 *    { id: 123, first_name: 'Leo', last_name: 'Tolstoi' },
 *    { id: 456, first_name: 'Jane', last_name: 'Austen' },
 * ];
 * const optionRenderer = choice => `${choice.first_name} ${choice.last_name}`;
 * <CheckboxGroupInput source="recipients" choices={choices} optionText={optionRenderer} />
 *
 * `optionText` also accepts a React Element, that will be cloned and receive
 * the related choice as the `record` prop. You can use Field components there.
 * @example
 * const choices = [
 *    { id: 123, first_name: 'Leo', last_name: 'Tolstoi' },
 *    { id: 456, first_name: 'Jane', last_name: 'Austen' },
 * ];
 * const FullNameField = ({ record }) => <span>{record.first_name} {record.last_name}</span>;
 * <CheckboxGroupInput source="recipients" choices={choices} optionText={<FullNameField />}/>
 *
 * The choices are translated by default, so you can use translation identifiers as choices:
 * @example
 * const choices = [
 *    { id: 'programming', name: 'myroot.category.programming' },
 *    { id: 'lifestyle', name: 'myroot.category.lifestyle' },
 *    { id: 'photography', name: 'myroot.category.photography' },
 * ];
 *
 * However, in some cases (e.g. inside a `<ReferenceInput>`), you may not want
 * the choice to be translated. In that case, set the `translateChoice` prop to false.
 * @example
 * <CheckboxGroupInput source="gender" choices={choices} translateChoice={false}/>
 *
 * The object passed as `options` props is passed to the material-ui <Checkbox> components
 */


var CheckboxGroupInput =
/** @class */
function (_super) {
  __extends(CheckboxGroupInput, _super);

  function CheckboxGroupInput() {
    var _this = _super !== null && _super.apply(this, arguments) || this;

    _this.handleCheck = function (event, isChecked) {
      var _a = _this.props.input,
          value = _a.value,
          onChange = _a.onChange;
      var newValue;

      try {
        // try to convert string value to number, e.g. '123'
        newValue = JSON.parse(event.target.value);
      } catch (e) {
        // impossible to convert value, e.g. 'abc'
        newValue = event.target.value;
      }

      if (isChecked) {
        onChange((value || []).concat([newValue]));
      } else {
        onChange(value.filter(function (v) {
          return v != newValue;
        })); // eslint-disable-line eqeqeq
      }
    };

    _this.renderCheckbox = function (choice) {
      var _a = _this.props,
          id = _a.id,
          value = _a.input.value,
          optionText = _a.optionText,
          optionValue = _a.optionValue,
          options = _a.options,
          translate = _a.translate,
          translateChoice = _a.translateChoice,
          classes = _a.classes;
      var choiceName = React.isValidElement(optionText) ? React.cloneElement(optionText, {
        record: choice
      }) : typeof optionText === 'function' ? optionText(choice) : get(choice, optionText);
      return React.createElement(FormControlLabel, {
        htmlFor: id + "_" + get(choice, optionValue),
        key: get(choice, optionValue),
        checked: value ? value.find(function (v) {
          return v == get(choice, optionValue);
        }) !== // eslint-disable-line eqeqeq
        undefined : false,
        onChange: _this.handleCheck,
        value: String(get(choice, optionValue)),
        control: React.createElement(Checkbox, __assign({
          id: id + "_" + get(choice, optionValue),
          color: "primary",
          className: classes.checkbox
        }, options)),
        label: translateChoice ? translate(choiceName, {
          _: choiceName
        }) : choiceName
      });
    };

    return _this;
  }

  CheckboxGroupInput.prototype.render = function () {
    var _a = this.props,
        choices = _a.choices,
        className = _a.className,
        _b = _a.classes,
        classes = _b === void 0 ? {} : _b,
        isRequired = _a.isRequired,
        label = _a.label,
        meta = _a.meta,
        resource = _a.resource,
        source = _a.source,
        input = _a.input,
        rest = __rest(_a, ["choices", "className", "classes", "isRequired", "label", "meta", "resource", "source", "input"]);

    if (typeof meta === 'undefined') {
      throw new Error("The CheckboxGroupInput component wasn't called within a redux-form <Field>. Did you decorate it and forget to add the addField prop to your component? See https://marmelab.com/react-admin/Inputs.html#writing-your-own-input-component for details.");
    }

    var touched = meta.touched,
        error = meta.error,
        _c = meta.helperText,
        helperText = _c === void 0 ? false : _c;
    return React.createElement(FormControl, __assign({
      className: className,
      component: "fieldset",
      margin: "normal"
    }, sanitizeRestProps(rest)), React.createElement(FormLabel, {
      component: "legend",
      className: classes.label
    }, React.createElement(FieldTitle, {
      label: label,
      source: source,
      resource: resource,
      isRequired: isRequired
    })), React.createElement(FormGroup, {
      row: true
    }, choices.map(this.renderCheckbox)), touched && error && React.createElement(FormHelperText, {
      error: true
    }, error), helperText && React.createElement(FormHelperText, null, helperText));
  };

  return CheckboxGroupInput;
}(Component);

export { CheckboxGroupInput };
CheckboxGroupInput.propTypes = {
  choices: PropTypes.arrayOf(PropTypes.object),
  classes: PropTypes.object,
  className: PropTypes.string,
  label: PropTypes.string,
  source: PropTypes.string,
  options: PropTypes.object,
  id: PropTypes.string,
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired
  }),
  isRequired: PropTypes.bool,
  optionText: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.element]).isRequired,
  optionValue: PropTypes.string.isRequired,
  resource: PropTypes.string,
  translate: PropTypes.func.isRequired,
  translateChoice: PropTypes.bool.isRequired,
  meta: PropTypes.object
};
CheckboxGroupInput.defaultProps = {
  choices: [],
  classes: {},
  options: {},
  optionText: 'name',
  optionValue: 'id',
  translateChoice: true
};
var EnhancedCheckboxGroupInput = compose(addField, translate, withStyles(styles))(CheckboxGroupInput);
EnhancedCheckboxGroupInput.defaultProps = {
  fullWidth: true
};
export default EnhancedCheckboxGroupInput;