var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0) t[p[i]] = s[p[i]];
  }
  return t;
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import GetApp from '@material-ui/icons/GetApp';
import { crudGetAll, downloadCSV, CRUD_GET_MANY, GET_MANY } from 'ra-core';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import Button from './Button';

var sanitizeRestProps = function sanitizeRestProps(_a) {
  var basePath = _a.basePath,
      crudGetAll = _a.crudGetAll,
      dispatch = _a.dispatch,
      exporter = _a.exporter,
      filter = _a.filter,
      maxResults = _a.maxResults,
      resource = _a.resource,
      sort = _a.sort,
      rest = __rest(_a, ["basePath", "crudGetAll", "dispatch", "exporter", "filter", "maxResults", "resource", "sort"]);

  return rest;
};
/**
 * Extracts, aggregates and deduplicates the ids of related records
 *
 * @example
 *     const books = [
 *         { id: 1, author_id: 123, title: 'Pride and Prejudice' },
 *         { id: 2, author_id: 123, title: 'Sense and Sensibility' },
 *         { id: 3, author_id: 456, title: 'War and Peace' },
 *     ];
 *     getRelatedIds(books, 'author_id'); => [123, 456]
 *
 * @example
 *     const books = [
 *         { id: 1, tag_ids: [1, 2], title: 'Pride and Prejudice' },
 *         { id: 2, tag_ids: [2, 3], title: 'Sense and Sensibility' },
 *         { id: 3, tag_ids: [4], title: 'War and Peace' },
 *     ];
 *     getRelatedIds(records, 'tag_ids'); => [1, 2, 3, 4]
 *
 * @param {Object[]} records An array of records
 * @param {string} field the identifier of the record field to use
 */


export var getRelatedIds = function getRelatedIds(records, field) {
  return Array.from(new Set(records.filter(function (record) {
    return record[field] != null;
  }).map(function (record) {
    return record[field];
  }).reduce(function (ids, value) {
    return ids.concat(value);
  }, [])));
};
/**
 * Helper function for calling the data provider with GET_MANY
 * via redux and saga, and getting a Promise in return
 *
 * @example
 *     fetchRelatedRecords(records, 'post_id', 'posts').then(posts =>
 *          posts.map(record => ({
 *              ...record,
 *              post_title: posts[record.post_id].title,
 *          }));
 */

var fetchRelatedRecords = function fetchRelatedRecords(dispatch) {
  return function (data, field, resource) {
    return new Promise(function (resolve, reject) {
      dispatch({
        type: CRUD_GET_MANY,
        payload: {
          ids: getRelatedIds(data, field)
        },
        meta: {
          resource: resource,
          fetch: GET_MANY,
          onSuccess: {
            callback: function callback(_a) {
              var data = _a.payload.data;
              resolve(data.reduce(function (acc, post) {
                acc[post.id] = post;
                return acc;
              }, {}));
            }
          },
          onFailure: {
            notification: {
              body: 'ra.notification.http_error',
              level: 'warning'
            },
            callback: function callback(_a) {
              var error = _a.error;
              return reject(error);
            }
          }
        }
      });
    });
  };
};

var ExportButton =
/** @class */
function (_super) {
  __extends(ExportButton, _super);

  function ExportButton() {
    var _this = _super !== null && _super.apply(this, arguments) || this;

    _this.handleClick = function () {
      var _a = _this.props,
          dispatch = _a.dispatch,
          exporter = _a.exporter,
          filter = _a.filter,
          maxResults = _a.maxResults,
          sort = _a.sort,
          resource = _a.resource,
          onClick = _a.onClick;
      dispatch(crudGetAll(resource, sort, filter, maxResults, function (_a) {
        var data = _a.payload.data;
        return exporter ? exporter(data, fetchRelatedRecords(dispatch), dispatch) : downloadCSV(convertToCSV(data), resource);
      }));

      if (typeof onClick === 'function') {
        onClick();
      }
    };

    return _this;
  }

  ExportButton.prototype.render = function () {
    var _a = this.props,
        label = _a.label,
        icon = _a.icon,
        rest = __rest(_a, ["label", "icon"]);

    return React.createElement(Button, __assign({
      onClick: this.handleClick,
      label: label
    }, sanitizeRestProps(rest)), icon);
  };

  ExportButton.propTypes = {
    basePath: PropTypes.string,
    dispatch: PropTypes.func,
    exporter: PropTypes.func,
    filter: PropTypes.object,
    label: PropTypes.string,
    maxResults: PropTypes.number.isRequired,
    resource: PropTypes.string.isRequired,
    sort: PropTypes.object,
    icon: PropTypes.element
  };
  ExportButton.defaultProps = {
    label: 'ra.action.export',
    maxResults: 1000,
    icon: React.createElement(GetApp, null)
  };
  return ExportButton;
}(Component);

export default connect()(ExportButton); // inject redux dispatch