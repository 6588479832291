var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0) t[p[i]] = s[p[i]];
  }
  return t;
};

import React from 'react';
import PropTypes from 'prop-types';
import onlyUpdateForKeys from 'recompose/onlyUpdateForKeys';
import MuiButton from '@material-ui/core/Button';
import { withStyles, createStyles } from '@material-ui/core/styles';
import ContentAdd from '@material-ui/icons/Add';
import compose from 'recompose/compose';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { translate } from 'ra-core';
import Button from './Button';
import Responsive from '../layout/Responsive';

var styles = function styles(theme) {
  return createStyles({
    floating: {
      color: theme.palette.getContrastText(theme.palette.primary.main),
      margin: 0,
      top: 'auto',
      right: 20,
      bottom: 60,
      left: 'auto',
      position: 'fixed',
      zIndex: 1000
    },
    floatingLink: {
      color: 'inherit'
    }
  });
};

var CreateButton = function CreateButton(_a) {
  var _b = _a.basePath,
      basePath = _b === void 0 ? '' : _b,
      className = _a.className,
      _c = _a.classes,
      classes = _c === void 0 ? {} : _c,
      translate = _a.translate,
      _d = _a.label,
      label = _d === void 0 ? 'ra.action.create' : _d,
      _e = _a.icon,
      icon = _e === void 0 ? React.createElement(ContentAdd, null) : _e,
      rest = __rest(_a, ["basePath", "className", "classes", "translate", "label", "icon"]);

  return React.createElement(Responsive, {
    small: React.createElement(MuiButton, __assign({
      component: Link,
      variant: "fab",
      color: "primary",
      className: classnames(classes.floating, className),
      to: basePath + "/create",
      "aria-label": label && translate(label)
    }, rest), icon),
    medium: React.createElement(Button, __assign({
      component: Link,
      to: basePath + "/create",
      className: className,
      label: label
    }, rest), icon)
  });
};

CreateButton.propTypes = {
  basePath: PropTypes.string,
  className: PropTypes.string,
  classes: PropTypes.object,
  label: PropTypes.string,
  size: PropTypes.string,
  translate: PropTypes.func.isRequired,
  icon: PropTypes.element
};
var enhance = compose(translate, onlyUpdateForKeys(['basePath', 'label', 'translate']), withStyles(styles));
export default enhance(CreateButton);