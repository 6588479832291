var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0) t[p[i]] = s[p[i]];
  }
  return t;
};

import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import pure from 'recompose/pure';
import Typography from '@material-ui/core/Typography';
import sanitizeRestProps from './sanitizeRestProps';
import { fieldPropTypes } from './types';

var toLocaleStringSupportsLocales = function () {
  // from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleString
  try {
    new Date().toLocaleString('i');
  } catch (error) {
    return error instanceof RangeError;
  }

  return false;
}();
/**
 * Display a date value as a locale string.
 *
 * Uses Intl.DateTimeFormat() if available, passing the locales and options props as arguments.
 * If Intl is not available, it outputs date as is (and ignores the locales and options props).
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleString
 * @example
 * <DateField source="published_at" />
 * // renders the record { id: 1234, published_at: new Date('2012-11-07') } as
 * <span>07/11/2012</span>
 *
 * <DateField source="published_at" className="red" />
 * // renders the record { id: 1234, new Date('2012-11-07') } as
 * <span class="red">07/11/2012</span>
 *
 * <DateField source="share" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }} />
 * // renders the record { id: 1234, new Date('2012-11-07') } as
 * <span>Wednesday, November 7, 2012</span>
 *
 * <DateField source="price" locales="fr-FR" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }} />
 * // renders the record { id: 1234, new Date('2012-11-07') } as
 * <span>mercredi 7 novembre 2012</span>
 */


export var DateField = function DateField(_a) {
  var className = _a.className,
      locales = _a.locales,
      options = _a.options,
      record = _a.record,
      _b = _a.showTime,
      showTime = _b === void 0 ? false : _b,
      source = _a.source,
      rest = __rest(_a, ["className", "locales", "options", "record", "showTime", "source"]);

  if (!record) {
    return null;
  }

  var value = get(record, source);

  if (value == null) {
    return null;
  }

  var date = value instanceof Date ? value : new Date(value);
  var dateString = showTime ? toLocaleStringSupportsLocales ? date.toLocaleString(locales, options) : date.toLocaleString() : toLocaleStringSupportsLocales ? date.toLocaleDateString(locales, options) : date.toLocaleDateString();
  return React.createElement(Typography, __assign({
    component: "span",
    variant: "body1",
    className: className
  }, sanitizeRestProps(rest)), dateString);
};
var EnhancedDateField = pure(DateField);
EnhancedDateField.defaultProps = {
  addLabel: true
};
EnhancedDateField.propTypes = __assign({}, Typography.propTypes, fieldPropTypes, {
  locales: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  options: PropTypes.object,
  showTime: PropTypes.bool
});
EnhancedDateField.displayName = 'EnhancedDateField';
export default EnhancedDateField;